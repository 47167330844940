import { render, staticRenderFns } from "./PendingVerification.vue?vue&type=template&id=68d80c09&scoped=true&"
import script from "./PendingVerification.vue?vue&type=script&lang=ts&"
export * from "./PendingVerification.vue?vue&type=script&lang=ts&"
import style0 from "./PendingVerification.vue?vue&type=style&index=0&id=68d80c09&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68d80c09",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
installComponents(component, {VBtn})
